import React, {FunctionComponent} from "react";
import Steps from "../../layout/Steps";
import Schedule from "../Schedule";
import MembersScreen from "../MembersScreen";
import MembersCheckout from "../MembersCheckout";
import Final from "../Final";
import {
    calculateDiscount,
} from "../../../lib/utils/helpers";
import './style.scss';
import {
    ISlot,
    TEventData, IBase,
} from "../../../lib/utils/types";
import ProductContainer from "../../common/ProductContainer";
import WrappedCounter from "./WrappedCounter";
import useSportContainer from "./hooks/useSportContainer";
import PeopleForms from "../PeopleForms";
import MemberCheckboxes from "../MembersScreen/MemberCheckboxes";
import i18next from "i18next";


const SportContainer: FunctionComponent<{page: string}> = ({page}) => {
    const {bookingCardProps, step, setStep, resetData, selectedSlot, currencies, eventData,
        selectedEvent, toStepTwo, peopleAmount, setPeopleAmount, basesList, handleTrainingData, setSelectedEvent,
        setSelectedSlot, event, url, orderId, order, isDataEntered, promocodeApplied, setBases, bases, setEventData,
        toStepThree, payingUser, setPayingUser, peopleData, isSubscriptionPay, subscriptionData, setIsSubscriptionPay,
        setPromocodeApplied, setCertificateApplied, promoAndCertDiscount, coachlessDiscount,
        fullBookDiscount, setOrder} = useSportContainer({page})
    const {t} = i18next;

    return <ProductContainer productType="sport" bookingCardProps={bookingCardProps}>
            <Steps step={step} handleStepClick={(step) => setStep(step)} resetData={resetData} />

            <WrappedCounter
                    step={step}
                    selectedSlot={selectedSlot}
                    currencies={currencies}
                    eventData={eventData}
                    selectedEvent={selectedEvent}
                    event={event} />
            {step < 2 && (
                <>
                    {selectedSlot?.show_booking_limit && peopleAmount === 20 ? 
                    <div className="booking-limit-warning">
                        {t('sport.schedule.selectors.secondBookingLimitWarning')}<a href={`mailto:${t('sport.schedule.selectors.BookingLimitEmail') as string}`}>
                            {t('sport.schedule.selectors.BookingLimitEmail') as string}</a> {t('sport.schedule.selectors.orCall')}
                            <a href={`tel:${t('sport.schedule.selectors.BookingLimitNumber') as string}`}>{t('sport.schedule.selectors.BookingLimitNumber') as string}</a>
                            {t('sport.schedule.selectors.makeItHappen')}
                    </div> 
                    : selectedSlot?.show_booking_limit && peopleAmount > 16 ? 
                    <div className="booking-limit-warning">
                        {t('sport.schedule.selectors.firstBookingLimitWarning')}<a href={`mailto:${t('sport.schedule.selectors.BookingLimitEmail') as string}`}>
                            {t('sport.schedule.selectors.BookingLimitEmail') as string}</a> {t('sport.schedule.selectors.orCall')}
                            <a href={`tel:${t('sport.schedule.selectors.BookingLimitNumber') as string}`}>{t('sport.schedule.selectors.BookingLimitNumber') as string}</a>
                            {t('sport.schedule.selectors.makeItHappen')}
                    </div> : <></>}
                    <Schedule
                        // @ts-ignore
                        globalEvent={selectedEvent}
                        onSubmit={toStepTwo}
                        peopleAmount={peopleAmount}
                        basesList={basesList}
                        sendData={(data:TEventData) => handleTrainingData(data)}
                        setPeopleAmount={setPeopleAmount}
                        setGlobalEvent={(event:TEventData) => setSelectedEvent(() => event)}
                        setSlot={(slot:ISlot) => setSelectedSlot(() => slot)}
                        eventURL={event}
                        slotURL={url}
                        orderId={orderId}
                        order={order}
                        isDataEntered={isDataEntered}
                        eventData={eventData}
                        promocodeApplied={promocodeApplied}
                        setFetchedBases={(bases:[IBase]) => setBases(bases)}
                        fetchedBases={bases}
                        currencies={currencies}
                        setEventData={setEventData}
                        page={page}
                    />
                </>
            )}

            {step === 2 && (
                <MembersScreen
                    finalize={(newData, orderId) => toStepThree(newData, orderId)}
                    order={order}
                    eventData={eventData}
                    step={step}
                    payingUser={payingUser}
                    setPayingUser={setPayingUser}
                    renderPeopleForms={({form, payingUser}: any) => <PeopleForms
                            form={form}
                            isEmailEditable={!payingUser?.email}
                            isLastnameEditable={!payingUser?.last_name || payingUser?.is_insurance}
                            isFirstnameEditable={!payingUser?.first_name || payingUser?.is_insurance}
                            tg_options={eventData?.slot?.tg_options || eventData?.slot?.event_id?.tg_options}
                            
                    />}
                    renderCheckboxes={({isInsuredBefore, dataContainer, peopleAmount, register, errors, values}: any) => <MemberCheckboxes
                            isInsuredBefore={isInsuredBefore}
                            dataContainer={dataContainer}
                            peopleAmount={peopleAmount}
                            register={register}
                            errors={errors}
                            values={values}
                    />}
                />
            )}
            {step === 3 && (
              <MembersCheckout
                  order={order}
                  inputMembersData={peopleData}
                  eventData={eventData}
                  step={step}
                  isSubscriptionPay={isSubscriptionPay}
                  subscriptionData={subscriptionData}
                  setIsSubscriptionPay={setIsSubscriptionPay}
                  payingUser={payingUser}
                  setPromocodeApplied={setPromocodeApplied}
                  setCertificateApplied={setCertificateApplied}
                  promoAndCertDiscount={promoAndCertDiscount}
                  additionalDiscount={calculateDiscount(coachlessDiscount, fullBookDiscount,
                          selectedSlot?.price_value || order?.slot?.price_value, peopleAmount,
                          eventData?.boat?.places || order?.boat?.places)}
                  setOrder={setOrder}
              />
            )}
            {step === 5 && (
                <Final payingUser={payingUser} orderId={orderId} order={order} setOrder={setOrder} onNewBookingClick={() => {
                    resetData()
                    setStep(1)
                }} />
            )}
        </ProductContainer>
};

export default SportContainer;
